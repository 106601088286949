import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Custom form validator for the SEPA mandate reference that maps to the client's number.
 */
export const clientReferenceFormValidator = (clientNumber: string): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const regexPattern = new RegExp(`^${clientNumber}(\\-\\d{4}\\-\\d{2}\\-\\d{2})?$`);
    const valid = regexPattern.test(control.value);
    return valid ? null : { invalidReference: { value: control.value } };
  };
};

export const mustBeObjectValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return typeof control.value === 'object' ? null : { mustBeObject: true };
  };
};
